@import "../base/variables";
.viibes{
  &__universities{
    &_main{
      &_content{
        &_list{
            h3 a{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &_item{
          display: flex;
          border-radius: 3rem;
          &_image{
            height: 100%;
            width: 44.125rem;
            border-radius: 3rem;
              flex-shrink: 0;
              object-fit: cover;
              filter: saturate(1.35);
          }
          &_main{
            display: flex;
            flex-direction: column;
            padding: 3rem 5rem 3rem 3rem;
              h3{
                  a:hover{
                      text-decoration: underline;
                  }
              }
            &_benefits{
              display: flex;
              flex-direction: column;
              margin-top: 2rem;
              gap: 1rem;
              & a{
                transition-duration: $transition_duration;
                &:hover{
                  filter: brightness(1.2);
                }
              }
              & p{
                font-weight: 500;
                font-size: 2rem;
                line-height: 144%;
              }
            }
            & .viibes__btn_link{
              margin-top: 3rem;
              font-size: 2.25rem;
              & img{
                width: 3.375rem;
                height: 3.375rem;
              }
            }
          }
        }
      }
      &_buttons{
        display: flex;
        gap: 3rem;
        align-items: center;
        margin-top: 4rem;
        &_button{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 7rem;
          height: 7rem;
          border-radius: 100%;
          transition-duration: $transition_duration;
          &:hover{
            filter: brightness(.8);
          }
          &:nth-of-type(1){
            & img{
              transform: rotate(180deg);
            }
          }
          & img{
            width: 4rem;
          }
        }
        &_list{
          display: flex;
          gap: 2rem;
          & a{
            display: block;
            font-weight: 700;
            font-size: 2.75rem;
            line-height: 152%;
            color: $text_gray;
            transition-duration: $transition_duration;
            &:hover{
              color: $text_purple;
            }
          }
        }
      }
    }

  }
}

@media (min-width: 768px) and (max-width: 991px){
  .viibes{
    &__universities{
      &_main_content{
        display: block;
        &_item{
          &_image{
            width: 32.625rem;
            height: 32.625rem;
            object-fit: cover;
          }
        }
        &_sidebar{
          display: none;
        }
      }
    }
  }
}


@media (min-width: 0) and (max-width: 767px){
  .viibes{
    &__universities {
      &_main{
        &_content{
          &_item{
            flex-direction: column;
            width: 100%;
            overflow: hidden;
              isolation: isolate;
            & .viibes__btn_link{
              font-size: 2rem;
              & img{
                width: 3rem;
                height: 3rem;
              }
            }
            &_main{
              padding: 3rem 2rem;
            }
            &_image{
              width: 100%;
              height: 27.5rem;
              border-radius: 0;
            }
          }
        }
        &_buttons{
          gap: 2rem;
          &_list{
            & a{
              font-size: 2.25rem;
            }
          }
          &_button{
            width: 6rem;
            height: 6rem;
          }
        }
      }
    }
  }
}


