.viibes {
    &__reviews {
        .viibes__header {
            & path {
                fill: $text_purple;
            }

            &_list {
                & a {
                    color: $text_gray;
                }
            }
        }
        &_preview{
            padding: 25rem 0 4rem 0;
        }
        .viibes__block_reviews{
            padding: 0 0 9rem 0;
        }
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .viibes{
        &__reviews{
            &_preview{
                padding: 25rem 0 7rem 0;
            }
            .viibes__block_reviews{
                padding: 0 0 7rem 0;
                &_list{
                    flex-wrap: wrap;
                    &_column{
                        width: 100%;
                    }
                    &_column:nth-of-type(3){
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }
        }
    }
}


@media (min-width: 0) and (max-width: 767px){
    .viibes{
        &__reviews{
            &_preview{
                padding: 18rem 0 3rem 0;
            }
            .viibes__block_reviews{
                padding: 0 0 7rem 0;
                & .swiper-horizontal{
                    width: 77vw;
                }
                &_list{
                    flex-direction: column;
                    gap: 2rem;
                    &_column{
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }
        }
    }
}
