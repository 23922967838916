@import "../base/variables";
.viibes{
  &__thanks{
    .viibes__header{
      & path{
        fill: $text_purple;
      }
      &_list{
        & a{
          color: $text_gray;
        }
      }
    }
    &_main{
      padding-top: 24rem;
      & .viibes__wrap{
        display: flex;
        flex-direction: column;
        gap: 6rem;
        //align-items: center;
        //text-align: center;
      }
      & p{
        margin-top: 1rem;
      }
        p.not_valid_number{
            color: #EA8D02;
            margin-top: 1rem;
            white-space: inherit !important;
            font-weight: 600;
            font-size: 2.75rem;
            line-height: 144%;
            a{
                color: #EA8D02;
            }
        }
      &_image{
        border-radius: 3rem;
        width: 37.5rem;
        height: 37.5rem;
      }
        &_video{
            border-radius: 3rem;
            width: 37.5rem;
            height: 37.5rem;
        }
    }
  }
}
@media (min-width: 0) and (max-width: 767px){
  .viibes{
    &__thanks {
      &_main{
          padding-top: 20rem;
        & .viibes__wrap{
          gap: 5rem;
        }
        &_image{
          width: 25rem;
          height: 25rem;
        }
          p.not_valid_number{
              font-size: 2rem;
          }
      }
    }
  }
}
