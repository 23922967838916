.viibes__map{
    .viibes__preview_tags{
        margin-top: 2rem;
        margin-bottom: 5rem;
        max-height: inherit !important;
    }
    #tags__blur_block{
        display: none !important;
    }
}
