.viibes{
    &__webinar{
        &_preview{
            overflow: hidden;
            isolation: isolate;
            position: relative;
            padding: 17rem 0;
            & .viibes__wrap{
                position: relative;
                z-index: 1;
            }
            & p{
                margin-top: 1.5rem;
                text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
            }
            & .viibes__h2{
                text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
                max-width: 117.5rem;
            }
            &_image{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
            &_gradient{
                position: absolute;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
                width: 100%;
                height: 100%;
                bottom: 0;
                top: 0;
                right: 0;
                left: 0;
            }
            &_buttons{
                display: flex;
                margin-top: 5rem;
                gap: 2.5rem;
            }
        }
        &_program{
            overflow: hidden;
            isolation: isolate;
            padding: 14rem 0;
            .viibes__wrap{
                display: flex;
                gap: 18.75rem;
            }
            &_image{
                border-radius: 3rem;
                width: calc(50% - 10px);
                height: auto;
                object-fit: cover;
            }
            &_content{
                display: flex;
                flex-direction: column;
                gap: 5rem;
                width: 50%;
                &_list{
                    display: flex;
                    flex-direction: column;
                    gap: 3rem;
                    &_item{
                        display: flex;
                        gap: 1.5rem;
                        & img{
                            width: 3rem;
                            height: 3rem;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .viibes{
        &__webinar{
            &_program{
                & .viibes__wrap{
                    gap: 3rem;
                }
                &_content{
                    width: 60%;
                    flex-shrink: 0;
                }
                &_image{
                    margin-right: -10rem;
                    width: calc(50% + 10rem);
                }
            }
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .viibes{
        &__webinar{
            &_preview{
                height: 100vh;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                padding: 0;
                & p{
                    margin-top: 3rem;
                }
                &_buttons{
                    flex-direction: column;
                    margin-top: 3rem;
                    gap: 2rem;
                }
            }
            &_program{
                padding: 11rem 0;
                &_content{
                    width: 100%;
                    gap: 3rem;
                    &_list{
                        gap: 2rem;
                    }
                }
                &_image{
                    display: none;
                }
            }
        }
    }
}
