@import "../base/variables";
.viibes{
  &__header{
    position: fixed;
    top: 0;
    background-color: transparent;
    width: 100%;
    z-index: 10;
      &_white{ //на внутренних страницах белый хедер
          & path{
              fill: $text_purple !important;
          }
          .viibes__header_list{
              a{
                  color: $text_gray !important;
              }
          }
      }
    .viibes__contacts_row_socials{
      display: none;
    }
    .viibes__wrap{
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    & svg {
      height: 5rem;
      & path{
        fill: $text_white;
      }
    }
    &_right{
      display: flex;
      gap: 5rem;
    }
    &_list{
      display: flex;
      gap: 3rem;
      align-items: center;
      & a{
        transition-duration: $transition_duration;
        color: $text_white;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    &_left{
      display: flex;
      gap: 2rem;
      align-items: center;
    }
    &_login{
      padding: 1.5rem 3rem;
      background: $bg_gray;
      border-radius: 30px;
      font-weight: 600;
      font-size: 2rem;
      line-height: 150%;
      color: $text_black;
      text-decoration: none;
      transition-duration: $transition_duration;
      &:hover{
        filter: brightness(0.8);
      }
    }
    &_mobile_menu{
      display: none;
    }
    &_active{
      box-shadow: 0 0 8px rgba(102, 102, 102, 0.25);
      background-color: $bg_white;
      & .viibes__header_list{
        & a{
          color: $text_gray;
        }
      }
      &  svg {
        height: 5rem;
        & path{
          fill: $text_purple;
        }
      }
    }
  }
  &__shadow{
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
      z-index: 8;
    display: none;
    &_active{
      display: block;
    }
  }
}

.burger{
  display: none;
  cursor: pointer;
  position: relative;
  transform: rotate(0deg);
  transition: 1s ease-in-out;
  &__line {
    display: block;
    position: absolute;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    background-color: $text_purple;
  }
  &.open{
    & .burger__line{
      &:nth-child(1) {
        width: 0;
        right: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        width: 0;
        right: 50%;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px){
  .burger{
    width: 7rem;
    height: 4.125rem;
    display: block;
    &__line:nth-child(1) {
      width: 7rem;
      top: 0;
    }
    &__line:nth-child(2) {
      width: 4.5rem;
      top: 1.75rem;
    }
    &__line:nth-child(3) {
      width: 4.5rem;
      top: 1.75rem;
    }
    &__line:nth-child(4) {
      width: 5.75rem;
      top: 3.5rem;
    }
    &__line {
      height: 0.625rem;
    }
    &.open{
      & .burger__line{
        &:nth-child(1) {
          top: 1.75rem;
        }
        &:nth-child(4) {
          top: 1.75rem;
        }
      }
    }
  }
  .viibes{
    &__header{
      z-index: 10;
      &_background{
        background-color: transparent;
        & svg{
          & path{
            fill: $text_white !important;
          }
        }
      }
      & .viibes__wrap{
        position: relative;
        z-index: 10;
      }
      &_list{
        display: none;
      }
      &_login{
        display: none;
      }
      &_mobile_menu{
        z-index: 9;
        position: fixed;
        width: 46.75rem;
        height: 100%;
        background-color: $bg_white;
        right: -46.75rem;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &_active{
          right: 0;
        }
        &_wrap{
          height: 85%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 3rem 0;
        }
        &_copyright{
          padding: 0 3rem;
        }
        &_list{
          display: flex;
          flex-direction: column;
        }
        &_item{
          padding: 1.5rem 3rem;
          font-weight: 600;
          font-size: 2.25rem;
          line-height: 144%;
          color: $text_black;
          &_active{
            background-color: $bg_purple;
            color: $text_purple
          }

        }
      }
    }
  }
}


@media (min-width: 0) and (max-width: 767px){
  .burger{
    width: 5rem;
    height: 3rem;
    display: block;
    &__line{
      height: 0.455rem;
      &:nth-child(1) {
        width: 5rem;
        top: 0;
      }
      &:nth-child(2) {
        width: 3.375rem;
        top: 1.26rem;
      }
      &:nth-child(3) {
        width: 3.375rem;
        top: 1.26rem;
      }
      &:nth-child(4) {
        width: 4.25rem;
        top: 2.5rem;
      }

    }
    &.open{
      & .burger__line{
        &:nth-child(1) {
          top: 1.75rem;
        }
        &:nth-child(4) {
          top: 1.75rem;
        }
      }
    }
  }
  .viibes{
    &__header{
      z-index: 10;
      &_background{
        background-color: transparent;
        & svg{
          & path{
            fill: $text_white !important;
          }
        }
      }
      & svg{
        height: 4rem;
      }
      &_left{
        .viibes__btn_slim{
          display: none;
        }
      }
      & .viibes__wrap{
        position: relative;
        z-index: 10;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      &_list{
        display: none;
      }
      &_login{
        display: none;
      }
      &_mobile_menu{
        z-index: 9;
        position: fixed;
        width: 37.5rem;
        height: 100%;
        background-color: $bg_white;
        right: -37.5rem;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &_consultation{
          margin-top: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          font-size: 2rem;
          line-height: 150%;
          color: $text_white;
          width: 100%;
          padding: 2rem 0;
          background-color: $bg_purple_cta;
        }
        &_active{
          right: 0;
        }
        &_wrap{
          height: 85%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 3rem 0;
        }
        &_copyright{
          padding: 0 3rem;
        }
        &_list{
          display: flex;
          flex-direction: column;
        }
        &_item{
          padding: 1rem 3rem;
          font-weight: 600;
          font-size: 2.25rem;
          line-height: 144%;
          color: $text_black;
          &_active{
            background-color: $bg_purple;
            color: $text_purple
          }

        }
      }
      .viibes__contacts_row_socials{
        display: flex;
        & img{
          width: 3.125rem;
          height: 3.125rem;
        }
        & .viibes__telegram_circle, .viibes__whatsapp_circle, .viibes__viber_circle, .viibes__instagram_circle, .viibes__facebook_circle{
          width: 5rem;
          height: 5rem;
        }
      }
      .viibes__copyright{
        gap: 1rem;
        &_hand{
          width: 4.25rem;
          height: 4.25rem;
        }
        &_content{
          & p, & span{
            font-size: 1.5rem;
          }
        }
      }
    }

  }
}
