@import "../base/variables";
.viibes{
    &__editor_block{
        margin-top: 4rem;
        &_main{
            border-top: 1px solid $bg_gray;
            padding-top: 3rem;
            &_inner{
                grid-column-gap: 3rem;
                display: grid;
                grid-template-columns: 7fr 3.5fr;
                &_content{
                    &_thumbnail{
                        width: 100%;
                        height: 45rem;
                        object-fit: cover;
                        border-radius: 3rem;
                        @media (min-width: 0) and (max-width: 767px){
                            height: 35rem;
                        }
                        margin-bottom: 2rem;
                    }
                }
            }
            h1{
                margin-bottom: 3rem;
                max-width: 1000px;
            }
        }
    }
    @media (min-width: 0px) and (max-width: 991px) {
        &__editor_block{
            &_main{
                &_inner{
                    display: inherit !important;
                }
            }
        }
    }
}
/////////////////////////// EDITOR
// EDITOR CONTENT
#viibes__editor_content{
    //background: #aceaac;
    img{
        width: 100% !important;
        height: 400px !important;
        margin: 2rem 0 1rem 0;
        object-fit: cover;
        border-radius: 3rem;
        @media (min-width: 0) and (max-width: 767px){
            height: 35rem !important;
        }
    }
    hr{
        margin-top: 5rem;
        border: none;
        background: $bg_gray;
        height: 1px;
    }
    h2, h3, h4, h5{
        margin-bottom: 0;
    }
    .viibes__editor_content__inner {
        h2, h3, h4, h5 {
            text-transform: lowercase;
        }

        h2:first-letter, h3:first-letter, h4:first-letter, h5:first-letter {
            text-transform: uppercase;
        }
    }
    p{
        font-size: 2rem;
        font-weight: 400;
        line-height: 144%;
        color: $text_gray;
        margin-top: 1rem;
        text-decoration: none;
    }
    h2{
        font-size: 4rem;
        font-weight: 600;
        line-height: 144%;
        color: #000000;
        text-decoration: none;
        margin-top: 4rem;
    }
    h3{
        font-size: 2.75rem;
        font-weight: 500;
        line-height: 144%;
        color: #000000;
        text-decoration: none;
        margin-top: 3rem;
    }
    h4{
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 144%;
        color: #000000;
        text-decoration: none;
        margin-top: 2rem;
    }
    h5{
        font-size: 2.25rem;
        font-weight: 400;
        line-height: 144%;
        color: #000000;
        text-decoration: none;
        margin-top: 2rem;
    }
    ol, ul{
        li{
            margin-top: 1rem;
            font-size: 2rem;
            color: $text_gray;
            line-height: 144%;
        }
        li::marker {
            font-weight: 600;
        }
    }
    a{
        color: $text_purple;
        text-decoration: underline;
    }
}
@media (min-width: 0) and (max-width: 767px) {
    #viibes__editor_content{
        p {
            font-size: 1.75rem;
        }
        h2 {
            font-size: 3rem;
        }
        h3 {
            font-size: 2.5rem;
        }
        h4 {
            font-size: 2rem;
        }
        h5 {
            font-size: 2rem;
        }
        ol, ul {
            padding-left: 3.75rem;
            li {
                font-size: 1.75rem;
            }
        }
    }
}
