@import "../base/variables";
.viibes{
  &__play_button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    &:hover{
      background: rgba(0, 0, 0, 0.4);
    }
    img{
      width: 3rem;
      height: 3.25rem;
    }
  }
}