.belarus{
    //главный экран
    .viibes__modal_benefits{
        top: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: rgba(247, 235, 254, 0.9);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        display: block;
        align-items: center;
        justify-content: center;
        &_inner{

        }
    }
    .viibes__home_preview{
        min-height: 800px;
        .viibes__home_preview_content{
            padding-top: 0;
            background: linear-gradient(135deg, rgba(20, 0, 40, 0.60) 32.98%, rgba(112, 25, 222, 0.11) 70.31%, rgba(112, 25, 222, 0.11) 100%);
            //backdrop-filter: blur(2.5px);
            #h1_belarus{
                img{
                    margin-bottom: 0 !important;
                }
                .belarus_ico, .eu_ico{
                    width: 5rem;
                    height: 5rem;
                }
                .arrow_ico{
                    width: 4rem;
                    height: 4rem;
                    padding-bottom: 4px;
                }
                span{
                    padding-left: 8px;
                }
            }
            .viibes__h1_sub{
                margin-top: 1.25rem;
            }
            .viibes__h_list{
                gap: 1.5rem;
                margin-top: 4rem;
                div{
                    display: inline-flex;
                    align-items: center;
                    img{
                        margin-right: 2rem;
                    }
                    .viibes__h1_sub{
                        max-width: inherit;
                        margin-top: 0 !important;
                        font-weight: 400;
                    }
                }
            }
            .viibes__home_preview_content_underside{
                margin-top: 5rem;
            }
        }
    }
    .viibes__our_numbers{
        display: inline-flex;
        div{
            width: 25%;
            padding: 5rem 7rem;
            span{
                font-weight: 700;
                line-height: 144%;
                font-size: 48px;
            }
            p{
                font-weight: 600;
                line-height: 144%;
                font-size: 16px;
            }
        }
        div:first-child{
            background: #F7EBFE;
            span, p{
                color: #A264F1;
            }
        }
        div:nth-child(2){
            background: #E8FEFD;
            span, p{
                color: #229ED9;
            }
        }
        div:nth-child(3){
            background: #F0FCF4;
            span, p{
                color: #06B648;
            }
        }
        div:last-child{
            background: #FEF7CC;
            span, p{
                color: #E5A801;
            }
        }
    }
    .viibes__our_product{
        padding: 136px 0;
        .viibes__h2_sub{
            margin-top: 1.25rem;
        }
        &_items{
            display: flex;
            flex-wrap: wrap;
            gap: 3rem;
            margin-top: 7rem;
            div{
                width: calc(50% - 1.5rem);
                .viibes__our_product_items_item{
                    border-radius: 24px;
                    border: 1px solid #EFEFEF;
                    width: calc(100% - 5.5rem);
                    padding: 2.75rem;
                    .viibes__h4{
                        font-weight: 600;
                        margin-bottom: 1rem;
                    }
                    p{
                        font-weight: 500;
                    }
                }
            }
            .left{
                .viibes__our_product_items_item{
                    &:first-child{
                        margin-bottom: 3rem;
                        background: linear-gradient(90deg, #FFFDEF 0%, rgba(255, 253, 239, 0.00) 100%), #FFF;
                        h3{
                            color: #E5A801;
                        }
                    }
                    &:last-child{
                        background: linear-gradient(90deg, #F8FBFF 0%, rgba(248, 251, 255, 0.00) 100%), #FFF;
                        h3{
                            color: #229ED9;
                        }
                    }
                }
            }
            .right{
                .viibes__our_product_items_item{
                    &:first-child{
                        margin-bottom: 3rem;
                        background: linear-gradient(90deg, #FCF6FF 0%, rgba(248, 251, 255, 0.00) 100%), #FFF;
                        h3{
                            color: #A264F1;
                        }
                    }
                    &:last-child{
                        background: linear-gradient(90deg, #F6FDF8 0%, rgba(246, 253, 248, 0.00) 100%), #FFF;
                        h3{
                            color: #06B648;
                        }
                    }
                }
            }
        }
    }
    .viibes__top_10{
        padding: 40px 0 136px 0;
        &_inner{
            display: flex;
            gap: 3rem;
            .left{
                width: 50%;
                .video_block{
                    width: 100%;
                    height: 400px;
                    border-radius: 3rem;
                    overflow: hidden;
                    position: relative;
                    video{
                        width: 100%;
                        height: 106%;
                        object-fit: cover;
                    }
                    .video_overlay{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.2);
                        -webkit-backdrop-filter: blur(1.5px);
                        backdrop-filter: blur(1.5px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div{
                            display: inline-flex;
                            gap: 12px;
                            align-items: center;
                            p{
                                color: #fff;
                                font-weight: 600;
                                font-size: 2rem;
                                line-height: 144%;
                            }
                        }
                    }
                }
                a{
                    display: none;
                }
            }
            .right{
                width: 50%;
                padding-top: 4rem;
                h2{
                    padding-bottom: 10px;
                }
                p{
                    margin-bottom: 6rem;
                }
            }
        }
    }
    .viibes__study_for_free{
        padding: 136px 0;
        .viibes__btn_link.viibes__mobile{
            display: none;
        }
        &_inner{
            width: 100%;
            display: inline-flex;
            gap: 3rem;
            margin-top: 7rem;
            .left{
                width: calc(50% - 1.5rem);
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 3rem;
                    object-fit: cover;
                }
            }
            .right{
                width: calc(50% - 1.5rem);
                display: flex;
                flex-wrap: wrap;
                gap: 3rem;
                &_item{
                    width: calc(50% - 7rem);
                    background: white;
                    padding: 2.75rem;
                    border-radius: 3rem;
                    h3{
                        font-weight: 600;
                    }
                    img{
                        margin-bottom: 2rem;
                    }
                }
            }
        }
        &_bottom{
            margin-top: 7rem;
            display: flex;
            justify-content: space-between;
        }
    }
    .viibes__slovakia_vs_belarus{
        padding: 136px 0;
        &_inner{
            padding-top: 7rem;
            display: inline-flex;
            gap: 3rem;
            &_col{
                width: calc(33.3% - 2rem);
                display: flex;
                gap: 3rem;
                flex-direction: column;
                .card{
                    div{
                        padding: 3rem;
                        display: inline-flex;
                        gap: 2rem;
                        align-items: flex-start;
                        p{
                            color: #000 !important;
                            font-weight: 400 !important;
                        }
                    }
                    div:first-child{
                        background: #F0FCF4;
                        border-radius: 3rem 3rem 0 0;
                        span{
                            color: #06B648;
                            font-weight: 600 !important;
                        }
                    }
                    div:last-child{
                        background: #FFF2F6;
                        border-radius: 0 0 3rem 3rem;
                        span{
                            color: #E1306C;
                            font-weight: 600 !important;
                        }
                    }
                }
            }
        }
    }
    .viibes__locally_in_sk{
        padding: 136px 0;
        h2{
            margin-bottom: 1.25rem;
        }
        &_items{
            margin-top: 5rem;
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 3rem;
            &_item{
                background: white;
                width: calc(33.3% - 2rem);
                padding: 2.75rem;
                border-radius: 3rem;
                gap: 1rem;
                display: flex;
                flex-direction: column;
                height: min-content;
                h3{
                    font-size: 22px;
                    line-height: 144%;
                    font-weight: 600;
                    margin: 0;
                }
                p{
                    font-size: 16px;
                    line-height: 144%;
                    color: $text_gray;
                    margin: 0;
                    font-weight: 500;
                }
            }
            &_item.yellow{
                h3{
                    color: #E5A801;
                }
            }
            &_item.blue{
                h3{
                    color: #229ED9;
                }
            }
            &_item.green{
                h3{
                    color: #06B648;
                }
            }
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .mobile__block_bottom_button{
        margin-top: 5rem;
        div{
            padding: 2.25rem 4rem;
            width: 100%;
            text-align: center;
        }
    }
    .belarus{
        .viibes__h2{
            font-size: 3rem !important;
        }
        .viibes__h2_sub{
            font-size: 16px !important;
        }
        .viibes__home_preview{
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
            min-height: inherit;
            overflow: hidden;
            padding-top: 0;
            position: inherit;
            height: inherit;
            .viibes__home_preview_content{
                position: inherit;
                .viibes__main_wrap_belarus{
                    margin-top: 21rem;
                    margin-bottom: 16rem;
                }
                #h1_belarus{
                    font-size: 27px;
                    br{
                        display: none;
                    }
                    .belarus_ico, .eu_ico{
                        width: 3rem;
                        height: 3rem;
                        position: relative;
                        bottom: -3px;
                    }
                    .arrow_ico{
                        width: 2rem;
                        height: 2rem;
                        padding-bottom: 4px;
                        position: relative;
                        bottom: -3px;
                    }
                }
                .viibes__h_list{
                    gap: 1.25rem;
                    margin-top: 2.75rem;
                    div{
                        img{
                            margin-right: 1.25rem;
                            width: 3rem;
                            height: 3rem;
                        }
                        .viibes__h1_sub{
                            font-size: 2.125rem;
                        }
                    }
                }
            }
        }
        .viibes__our_numbers{
            display: flex;
            flex-wrap: wrap;
            margin-top: -5rem;
            div{
                width: calc(50% - 6rem);
                padding: 3rem 3rem 3rem 3rem;
                &:first-child, &:nth-child(2){
                    padding-top: 6rem;
                }
                span{
                    font-weight: 700;
                    line-height: 144%;
                    font-size: 40px;
                }
                p{
                    font-weight: 600;
                    line-height: 144%;
                    font-size: 13px;
                }
            }
        }
        .viibes__our_product{
            padding: 88px 0;
            overflow: hidden;
            img{
                width: 100%;
                height: 250px;
                object-fit: cover;
                border-radius: 3rem;
                margin-top: 5rem;
            }
            &_items{
                display: block;
                margin-top: 3rem;
                position: relative;
                div{
                    width: 100%;
                    .viibes__our_product_items_item{
                        width: calc(100% - 5.5rem);
                        padding: 2.75rem;
                        .viibes__h4{
                            font-size: 2.25rem;
                        }
                    }
                }
                .left, .right{
                    .viibes__our_product_items_item{
                        background: white !important;
                        &:first-child{
                            margin-bottom: 2rem;
                        }
                    }
                }
                .right{
                    margin-top: 2rem;
                }
                &_background{
                    position: absolute;
                    width: 1000px !important;
                    height: 200%;
                    top: -20%;
                    left: -55%;
                    transform: rotate(8deg);
                    z-index: -1;
                    background: #EFEFEF;
                }
            }
        }
        .viibes__top_10{
            padding: 88px 0;
            &_inner{
                display: flex;
                gap: 3rem;
                flex-direction: column;
                .left{
                    width: 100%;
                    order: 2;
                    .video_block{
                        height: 450px;
                        .video_overlay{
                            div{
                                gap: 1rem;
                                p{
                                    font-size: 1.75rem;
                                }
                            }
                        }
                    }
                    a{
                        display: flex !important;
                        margin-top: 3rem;
                    }
                }
                .right{
                    width: 100%;
                    padding-top: 0;
                    order: 1;
                    h2{
                        padding-bottom: 1rem;
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .viibes__study_for_free{
            padding: 88px 0;
            overflow: hidden;
            a.viibes__mobile{
                margin-top: 3rem;
                display: block !important;
                line-height: 70%;
                img{
                    position: relative;
                    top: 6px;
                }
            }
            &_inner{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 1;
                margin-top: 5rem;
                .left{
                    width: 100%;
                    img{
                        height: 250px;
                    }
                }
                .right{
                    width: 100%;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    gap: 1.25rem;
                    &_item{
                        width: calc(100% - 5.5rem);
                        padding: 2.75rem;
                        display: inline-flex;
                        gap: 1.75rem;
                        h3{
                            font-weight: 600;
                            font-size: 1.75rem;
                        }
                        img{
                            margin-bottom: 0;
                            width: 5rem;
                            height: 5rem;
                        }
                    }
                }
            }
            &_bottom{
                margin-top: 5rem;
                div{
                    width: 100%;
                    text-align: center;
                }
            }
            .viibes__study_for_free_background{
                position: absolute;
                width: 1000px !important;
                height: 100%;
                top: 26%;
                left: -55%;
                transform: rotate(18deg);
                z-index: 0;
                background: #EFEFEF;
            }
        }
        .viibes__slovakia_vs_belarus{
            padding: 88px 0;
            &_inner{
                padding-top: 5rem;
                display: flex;
                flex-direction: column;
                gap: 2.25rem;
                &_col{
                    width: 100%;
                    gap: 2.25rem;
                    .card{
                        img{
                            width: 28px;
                            height: 28px;
                        }
                        div{
                            padding: 2rem;
                            gap: 1rem;
                        }
                    }
                }
            }
        }
        .viibes__locally_in_sk{
            padding: 88px 0;
            overflow: hidden;
            img{
                width: 100%;
                height: 250px;
                border-radius: 3rem;
                margin-top: 5rem;
                position: relative;
                z-index: 3;
                object-fit: cover;
            }
            &_items{
                margin-top: 3rem;
                flex-direction: column;
                gap: 2rem;
                position: relative;
                z-index: 2;
                &_item{
                    width: calc(100% - 5.5rem);
                    height: inherit;
                    h3{
                        font-size: 18px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
                &_background{
                    position: absolute;
                    width: 1000px !important;
                    height: 170%;
                    top: -20%;
                    left: -55%;
                    transform: rotate(8deg);
                    z-index: -1;
                    background: #EFEFEF;
                }
            }
            .mobile__block_bottom_button{
                position: relative;
                z-index: 2;
            }
        }
    }
}
