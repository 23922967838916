$text_instagram:#E1306C;
$text_facebook:#4267B2;
$text_telegram:#229ED9;
$text_whatsapp:#25D366;
$text_viber:#7360F2;
//$bg_instagram:#FDEBF1;
//$bg_facebook:#EDF0F8;
//$bg_telegram:#E9F6FC;
//$bg_whatsapp:#EAFBF0;
//$bg_viber:#F2F0FE;
$bg_instagram:rgba(225, 48, 108, 0.1);
$bg_facebook:rgba(66, 103, 178, 0.1);
$bg_telegram:rgba(34, 158, 217, 0.1);
$bg_whatsapp:rgba(37, 211, 102, 0.1);
$bg_viber:rgba(115, 96, 242, 0.1);
$bg_instagram__hover:rgba(225, 48, 108, 0.2);
$bg_facebook__hover:rgba(66, 103, 178, 0.2);
$bg_telegram__hover:rgba(34, 158, 217, 0.2);
$bg_whatsapp__hover:rgba(37, 211, 102, 0.2);
$bg_viber__hover:rgba(115, 96, 242, 0.2);
$text_placeholder:#A5A5A5;
$text_black:#000000;
$text_white:#FFFFFF;
$text_gray:#5D606D;
$bg_white:#FFFFFF;
$bg_gray:#F4F5F6;
$bg_gray_hover:#C3C4C5;
$text_purple:#A264F1;
$text_light_blue:#229ED9;
$text_yellow:#E5A801;
$text_blue:#4373EB;
$bg_purple:#F7EBFE;
$bg_light_blue:#E8FAFE;
$bg_yellow:#FEF7CC;
$bg_blue:#E4EFFF;
$bg_purple_cta:#A264F1;
$transition_duration:0.3s;
