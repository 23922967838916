@import "../base/variables";
.viibes{
  &__modal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(.5rem);
    z-index: 15;
    display: none;
    &_active{
      display: block;
    }
    &_close{
      cursor: pointer;
      width: 7rem;
      height: 7rem;
      position: absolute;
      top: 5rem;
      right: 11.25rem;
      transition-duration: $transition_duration;
      fill: $text_white;
      &:hover{
        filter: brightness(.8);
      }
    }
    &_inner{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & video{
      max-width: 80%;
      max-height: 80%;
      border-radius: 3rem;
    }
    &_loader{
      position: fixed;
      background: rgba(247, 235, 254, 0.9);
      backdrop-filter: blur(4px);
      z-index: 1000;
      height: 100%;
      width: 100%;
        top: 0;
        left: 0;
        right: 0;
      justify-content: center;
      align-items: center;
      display: none;
      & img{
        width: 9.16625rem;
        height: 9.16625rem;
        animation: 1s linear 0s normal none infinite running spin;
      }
      &_active{
        display: flex;
      }
    }
    &_form{
        top: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: rgba(247, 235, 254, 0.9);
      backdrop-filter: blur(4px);
      display: none;
      align-items: center;
      justify-content: center;
        &::-webkit-scrollbar{
            width: 0;
        }
      &_activator{
        cursor: pointer;
      }
      &_active{
        display: flex;
      }
      & .viibes__cta_form{
        background-color: unset!important;
      }
      & .viibes__modal_close{
        fill: $text_purple;
      }
    }
  }

}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg)
  }
}

#viibes__modal_benefits{
    .viibes__modal_benefits_main_content{
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
        &_item{
            width: calc(33.3% - 2rem);
            position: relative;
            height: 300px;
            overflow: hidden;
            border-radius: 3rem;
            &_background{
                position: absolute;
                z-index: -1;
                transform: rotate(8deg);
                height: 93%;
                bottom: -35%;
                width: 140%;
                left: -35%;
                background: rgba(46, 29, 68, 0.75);
            }
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }
            &_content{
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                h3{
                    font-size: 40px;
                    font-weight: 700;
                    color: white;
                    line-height: 144%;
                    margin: 0;
                }
                p{
                    font-size: 16px;
                    line-height: 144%;
                    font-weight: 500;
                    color: white;
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px){
  .viibes{
    &__modal{
        &_form{
            align-items: unset;
        }
      &_close{
        top: 4rem;
        right: 2rem;
      }
    }
  }
}

@media (min-width: 0) and (max-width: 767px){
  .viibes{
    &__modal{
        &_form{
            align-items: unset;
        }
      &_close{
        width: 5rem;
        height: 5rem;
        top: 2rem;
        right: 2rem;
      }
      & video{
        max-width: 100%;
        max-height: calc(100% - (5rem + 2rem + 2rem)*2);
        border-radius: 1rem;
      }
    }
  }
    #viibes__modal_benefits{
        .viibes__modal_benefits_main_content{
            flex-wrap: nowrap;
            gap: 2rem;
            flex-direction: column;
            padding-bottom: 5rem;
            &_item{
                margin: 0 2rem;
                width: calc(100% - 4rem);
                &:nth-child(4){
                    height: 350px;
                }
                &_background{
                    position: absolute;
                    z-index: -1;
                    transform: rotate(8deg);
                    height: 93%;
                    bottom: -35%;
                    width: 140%;
                    left: -35%;
                    background: rgba(46, 29, 68, 0.75);
                }
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: -1;
                }
                &_content{
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    h3{
                        font-size: 40px;
                        font-weight: 700;
                        color: white;
                        line-height: 144%;
                        margin: 0;
                    }
                    p{
                        font-size: 16px;
                        line-height: 144%;
                        font-weight: 500;
                        color: white;
                        margin: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}
