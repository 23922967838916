//include base files
@import "base/normalize";
@import "base/typography";
@import "base/variables";
@import "base/system";


//include components files
@import "components/form";
@import "components/social";
@import "components/drop";
@import "components/video-button";
@import "components/contacts";
@import "components/copyright";

//include layout files
@import "layout/header";
@import "layout/footer";
@import "layout/sidebar";
@import "layout/layout";

//include blocks files
@import "blocks/cta";
@import "blocks/seo";
@import "blocks/reviews";
@import "blocks/faq";
@import "blocks/top-universities";
@import "blocks/cards";
@import "blocks/modal";
@import "blocks/content";

//include pages files
@import "pages/home";
@import "pages/services";
@import "pages/universities";
@import "pages/university";
@import "pages/faculty";
@import "pages/thank-you";
@import "pages/course";
@import "pages/contacts";
@import "pages/webinar";
@import "pages/reviews";
@import "pages/login";
@import "pages/map";
@import "pages/belarus";
//@import "libraries/gallery"; если импортировать - выдает ошибку

//additional css
.swiper-button-disabled{
    opacity: 0.7;
    pointer-events: none;
}
video{
    background: #7C49BC;
}
.viibes__course_preview{
    .viibes__seo_breadcrumbs_items{
        margin-top: 0 !important;
    }
}

.viibes__faq_page_items .viibes__faq_page_items_item{
    margin: 2rem 0;
}
.viibes__faq_page_items hr{
    margin: 6rem 0;
    border: 1px solid #f4f5f6;
}

.viibes__site_content{
    display: flex;
    flex-direction: column;
    .viibes__seo_text_block{
        order: 1;
    }
}

#h2_belarus{
    font-size: 46px;
}
#h2_belarus u{
    text-decoration-color: #a364f1;
    text-decoration-thickness: 3px;
}
#h2_belarus .br_m{
    display: none;
}
@media (min-width: 0) and (max-width: 767px){
    #h2_belarus .br_d{
        display: none;
    }
    #h2_belarus .br_m{
        display: block;
    }
    #h2_belarus{
        font-size: 3rem;
    }
    .viibes__main_wrap_belarus{
        margin-top: -10rem;
    }
}
.viibes__h_list{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 20px;
}

//ukraine colors logo
#svg-ukraine .blue{
    fill: #2D6DDA;
}
#svg-ukraine .yellow{
    fill: #EFD046;
}

//programs filter
#select_degree__form_select{
    border: none;
    border-radius: 1rem;
    padding: 5px 36px 5px 16px;
    text-align: center;
}
#select_degree__form_select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image: url('/assets/client/images/components/arrow_down.svg');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;

}
#select_degree__form label{
    margin-right: 2rem;
}

//HIDE directions

@media (min-width: 0) and (max-width: 767px){
    #select_degree__form{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: -1rem;
    }
    #viibes__universities_preview_tags{
        max-height: 156px;
        overflow: hidden;
        position: relative;
    }
    .viibes__universities_preview_tags_open{
        max-height: inherit !important;
        padding-bottom: 32px;
    }
    #directions__blur_block{
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 64px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.807672) 41.67%, rgba(255, 255, 255, 0.776707) 51.56%, rgba(255, 255, 255, 0.848958) 73.44%, #FFFFFF 100%);
    }
    .directions__blur_block_open{
        background: none !important;
    }
    #directions__open_all_arrow{
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .directions__open_all_arrow_open{
        transform: scale(1, -1) translateX(-50%) !important;
    }
}

//BLOG -----------------------
.blog_posts{
    hr{
        background: $bg_gray;
        height: 1px;
        border-radius: 0.5px;
        width: 100%;
        border: none;
    }
    .blog_post_item{
        .blog_post_item__image{
            width: 37.5%;
            img{
                width: 100%;
                height: 100%;
                max-height: 244px;
                border-radius: 3rem;
                object-fit: cover;
            }
        }
        display: flex;
        flex-direction: row;
        gap: 3rem;
        margin: 4rem 0 4rem 0;
        &:last-child{
            margin: 4rem 0 0 0;
        }
        &:first-child{
            margin: 0 0 4rem 0;
        }
        .blog_post_item_text{
            width: 62.5%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .blog_post_item_text_inner{
                h2{
                    a{
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .blog_post_item_text_inner_category{
                    display: inline-flex;
                    align-items: center;
                    gap: 1rem;
                }
            }
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .blog_posts{
        .blog_post_item{
            .blog_post_item__image{
                width: 100%;
                height: 200px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            gap: 1rem;
            flex-direction: column;
            margin: 4rem 0 3rem 0;
            &:last-child{
                margin: 4rem 0 0 0;
            }
            &:first-child{
                margin: 0 0 3rem 0;
            }
            .blog_post_item_text{
                width: 100%;
                img{
                    width: inherit;
                    height: inherit;
                }
            }
        }
    }
}
.blog_sidebar{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    hr{
        background: $bg_gray;
        height: 1px;
        border-radius: 0.5px;
        width: 100%;
        border: none;
    }
    .blog_sidebar_group{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        h3{
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 144%;
            color: #000000;
            margin: 0;
        }
        .blog_sidebar_group_posts{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .blog_sidebar_group_post{
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                h4{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 144%;
                    margin: 0;
                }
                a{
                    color: #000000;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                .blog_sidebar_group_post_category{
                    display: inline-flex;
                    align-items: center;
                    gap: 1rem;
                }
            }
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .blog_sidebar {
        border-top: 1px solid #F4F5F6;
        padding-top: 4rem;
        margin-top: 5rem;
    }
}
#post{
    img{
        width: 100%;
        height: 450px;
        border-radius: 3rem;
        object-fit: cover;
    }
}
@media (min-width: 0) and (max-width: 767px){
    #post{
        img{
            height: 30rem;
        }
    }
}
// //BLOG -----------------------


.viibes__login{
    .viibes__form{
        flex-direction: column;
        margin-top: 0;
        width: 100%;
        max-width: 500px;
        input{
            background: $bg_gray;
            &[type=checkbox]{
                width: 22px !important;
                height: 22px !important;
                accent-color: #A264F1;
            }
        }
    }
}

//SERVICES TARIFF PAGE
.service_page{
    .viibes__bg_purple_cta{
        padding: 2rem 0;
        .viibes__wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        h2{
            font-weight: 600 !important;
        }
    }
    .viibes__bg_white, .viibes__bg_gray, .viibes__bg_purple{
        padding: 4rem 0;
        .viibes__wrap{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            h4{
                margin-top: 1rem;
            }
            ul{
                margin: 0;
                padding: 0 26px;
                li{
                    margin-top: 1rem;
                }
            }
            .service_page_item_list{
                div{
                    display: flex;
                    gap: 1rem;
                    align-items: flex-start;
                    margin-top: 1rem;
                    &.true{
                        .ico{
                            content: url('/assets/client/images/services/true.svg');
                        }
                    }
                    &.false{
                        .ico{
                            content: url('/assets/client/images/services/false.svg');
                        }
                        color: #A5A5A5 !important;
                    }
                    &.plus{
                        .ico{
                            content: url('/assets/client/images/services/plus.svg');
                        }
                    }
                    &.minus{
                        .ico{
                            content: url('/assets/client/images/services/minus.svg');
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 0) and (max-width: 767px){
        .viibes__h3{
            font-size: 2.5rem !important;
        }
        .viibes__h2{
            font-size: 2.8rem !important;
        }
        .viibes__h4{
            font-size: 2.3rem !important;
        }
    }
}

.programs_by_direction{
    #viibes__editor_content{
        hr{
            margin-top: 3rem !important;
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .programs_by_direction{
        #viibes__editor_content{
            hr{
                margin-top: 2rem !important;
            }
        }
    }
}

.viibes__top_banner{
    position: relative;
    background: #EAB934;
    transition: 0.3s;
    &_show{
        top: 0;
    }
    &_hide{
        top: -100%;
    }
    .viibes__wrap{
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &_bg_orange, &_bg_yellow{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
    }
    &_bg_orange{
        width: 60%;
        background: #EA9301;
        clip-path: polygon(0 0, 100% 0%, calc(100% - 15px) 100%, 0% 100%);
    }
    &_bg_yellow{
        width: 70%;
        background: $text_yellow;
        clip-path: polygon(0 0, 100% 0%, calc(100% - 15px) 100%, 0% 100%);
    }
    p{
        font-weight: 800;
        font-size: 22px;
        line-height: 144%;
        z-index: 2;
        color: $text_white;
        br{
            display: none;
        }
    }
    a{
        background: $bg_white;
        color: $text_purple;
        padding: 10px 4rem;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 152%;
        border-radius: 4rem;
        transition-duration: 0.3s;
        white-space: nowrap;
        z-index: 2;
        &:hover{
            background: #e8e8e8;
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .viibes__top_banner{
        &_bg_orange{
            display: none;
        }
        &_bg_yellow {
            width: 54%;
        }
        p{
            font-size: 14px;
            line-height: 132%;
            span{
                display: none;
            }
            br{
                display: block;
            }
        }
        a{
            padding: 9px 18px;
            font-size: 12px;
        }
    }
}

.header-unpin{
    /* display: none; */
    transform: translateY(-65px);
}
.header-pin{
    /* display: block; */
    transform: translateY(0);
}

#cta_form_phone{
    padding-left: 94px !important;
}
.iti{
    width: 100%;
}
.iti__flag-container{
    padding: 8px !important;
    .iti__selected-flag{
        padding: 0 20px 0 22px;
        background: #F4F5F6;
        border-radius: 40px;
        gap: 2px;
        .iti__flag{
            box-shadow: none !important;
            border-radius: 2px;
        }
        .iti__arrow{
            border-left: 5.5px solid transparent !important;
            border-right: 5.5px solid transparent !important;
            border-top: 6.5px solid #555 !important;
            border-radius: 2px;
        }
    }
}
.iti__country-list{
    border-radius: 16px;
    .iti__country{
        padding: 17px 17px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        .iti__country-name, .iti__dial-code{
            font-size: 14px;
            white-space: nowrap;
            font-weight: 500;
        }
    }
    .iti__flag{
        border-radius: 2px;
    }
}
@media (min-width: 0) and (max-width: 767px){
    #cta_form_phone{
        padding-left: 82px !important;
    }
    .iti__flag-container{
        .iti__selected-flag{
            padding: 0 14px 0 16px;
        }
    }
    .iti--container{
        top: 0 !important;
        left: 0 !important;
        padding: 16px !important;
        width: calc(100vw - 32px) !important;
        background: #0000004A !important;
        height: 100vh !important;
        display: flex !important;
        align-items: center !important;
        .iti__country-list{
            border-radius: 10px;
            box-shadow: none !important;
            border: none !important;
            width: calc(100% - 32px) !important;
            overflow-x: hidden !important;
            max-height: 300px !important;
            .iti__country{
                padding: 17px 17px !important;
                display: flex;
                flex-direction: row;
                align-items: center;
                .iti__country-name, .iti__dial-code{
                    font-size: 14px;
                    white-space: nowrap;
                    font-weight: 500;
                }
            }
        }
    }
}
.form_validate_errors{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .form_validate_error{
        border-radius: 20px;
        background: #F8DFF0;
        padding: 16px 24px;
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        align-items: center;
        span{
            color: #FF5252;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 24px */
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .form_validate_errors {
        .form_validate_error {
            margin-top: 3rem;
            padding: 12px 14px;

            span {
                font-size: 1.8rem;
            }
        }
    }
}
#cta_form_name, #cta_form_phone{
    //outline-offset: -2px;
    //outline: 2px solid white;
    box-shadow: inset 0 0 0 2px white;
    -webkit-box-shadow: inset 0 0 0 2px white;
    transition-duration: 0.05s;
}














