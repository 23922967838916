@import "../base/variables";
.viibes{
  &__home{
    &_preview{
      padding-top: 8rem;
      box-sizing: border-box;
      width: 100vw;
      height: 100vh;
      position: relative;
      &_title{
        display: flex;
      }
      &_content{
        padding-top: 4rem;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .viibes__h1_sub{
          max-width: 110rem;
          margin-top: 2rem;
        }
        &_underside{
          display: flex;
          gap: 2rem;
          margin-top: 8rem;
          align-items: center;
        }
      }
      &_video{
        width: 100%;
        height: 100%;
          min-height: 113vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;

      }
      & .viibes__h1{
          max-width: 100%;
          font-size: 7rem;
        & img{
          height: 10rem;
          margin-bottom: -2.5rem;
        }
      }
    }
    &_help{
      padding: 14rem 0;
      &_cards{
        display: flex;
        gap: 3rem;
        margin-top: 5rem;
        &_column{
          display: flex;
          flex-direction: column;
          gap: 3rem;
          &:nth-of-type(1){
            max-width: 90.625rem;
          }
        }
        &_item{
          display: flex;
          flex-direction: column;
          gap: 3rem;
          padding: 5rem;
          border-radius: 3rem;
          overflow: hidden;
            isolation: isolate;
          &_flag{
            margin-top: 2rem;
            width: 9rem;
            height: 9rem;
          }
          &_small{
            display: flex;
            flex-direction: column;
            gap: 3rem;
          }
          &_main{
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }
          &_mark{
            padding: 1.125rem 3rem 1.125rem 5rem;
            margin-left: -5rem;
            font-weight: 600;
            font-size: 2.25rem;
            line-height: 152%;
            width: fit-content;
            border-radius: 0 3rem 3rem 0;
          }
          &_underside{
            display: flex;
            width: calc(100% + 5rem);
            gap: 3rem;
            margin-right: -5rem;
            margin-bottom: -5rem;
            justify-content: space-between;
            &_media{
              width: 38.75rem;
              //height: 48rem;
              border-radius: 3rem 0 0 0;
              object-fit: cover;
                filter: opacity(1.3);
            }
            &_content{
              display: flex;
              flex-direction: column;
              gap: 4rem;
              margin-bottom: 5rem;
            }
            &_list{
              display: flex;
              flex-direction: column;
              gap: 3rem;
            }
            &_item{
              display: flex;
              gap: 1rem;
              & img{
                height: fit-content;
              }
            }
          }
        }
      }
    }
    &_possibility{
      padding: 14rem 0;
      &_content{
        margin-top: 5rem;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr;
        gap: 3rem;
        grid-auto-rows: 59.25rem;
        &_item{
          position: relative;
          &:nth-child(1){
            grid-column-start: 1;
            grid-row-start: 1;
            grid-row-end: 3;
          }
          &:nth-child(2){
            max-height: 32rem;

            &:hover video{
              transform: scale(1.1);
            }
          }
          &:nth-child(3){
            flex-direction: column;
            display: flex;
            margin-top: -2rem;
          }
          & p{
            margin-top: 2rem;
          }
          &_image{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3rem;
            //max-height: 62.25rem;
          }
          & video{
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition-duration: $transition_duration;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px){
  .viibes{
    &__home {
      &_preview{
      }
      &_help{
        &_cards{
          flex-wrap: wrap;
          gap: 2rem;
          &_item{
            height: fit-content;
          }
          &_column{
            gap: 2rem;
            &:nth-of-type(1){
              max-width: unset;
            }
            &:nth-of-type(2){
              display: grid;
              grid-template-columns: 1fr 1fr;
            }

          }
        }
      }
      &_possibility{
        &_content{
          display: flex;
          flex-wrap: wrap;
          row-gap: 3rem;
          column-gap: 2rem;
          &_item{
            &:nth-child(1), &:nth-child(2){
              width: calc((100% - 3rem)/2);
              height: 49.125rem;
              max-height: unset;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 767px){
  .viibes{
    &__home {
      &_preview{
          & .viibes__h1{
              font-size: 3.9rem;
          }
        & .viibes__h1 img{
          height: 7.5rem;
          margin-bottom: -2rem;
        }
        &_content{
          & .viibes__h1_sub{
            margin-top: 1rem;
          }
          &_underside{
            margin-top: 4rem;
            &_group{
              position: absolute;
              left: 2rem;
              bottom: 1.75rem;
            }
          }
        }
      }
      &_help{
        padding: 11rem 0;
        &_cards{
          flex-wrap: wrap;
          gap: 2rem;
          &_column{
            &:nth-of-type(2)
            {
              flex-direction: column;
              display: flex;
            }
          }
          &_item{
            height: fit-content;
            padding: 3rem 2rem;
            gap: 2rem;
            &_flag{

              width: 6rem;
              height: 6rem;
            }
            &_mark{
              padding: 1rem 2rem;
              margin-left: -2rem;
              font-size: 14px;
            }
            &_underside{
              flex-direction: column;
              width: 100%;
              margin-bottom: -3rem;
              gap: 4rem;
                video{
                    border-radius: 3rem 3rem 0 0;
                    width: 100%;
                    height: auto;
                    max-height: 40rem;
                }
              &_content{
                gap: 3rem;
                margin-bottom: 0;
              }
              &_list{
                gap: 2rem;
              }
              &_image{
                width: calc(100% + 4rem);
                margin-left: -2rem;
                border-radius: unset;
                max-height: 40rem;
                height: unset;
              }
            }
          }
          &_column{
            gap: 2rem;
            &:nth-of-type(1){
              max-width: unset;
            }
          }
        }
      }
      &_possibility{
        padding: 11rem 0;
        &_content{
          display: flex;
          flex-direction: column;
          margin-top: 3rem;
          gap: 2rem;
          &_item{
            &_image{
              max-height: 43rem;
            }
            &:nth-child(2){
              max-height: 25rem;
            }
            &:nth-child(3){
              margin-top: 0;
            }
            & p{
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}


//belarus
.viibes__home_slovakia_vs_belarus{
    padding: 14rem 0;
    &_inner{
        margin-top: 5rem;
        display: flex;
        flex-direction: row;
        &_left, &_right{
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            padding-right: 5rem;
            div{
                display: flex;
                gap: 2rem;
                align-items: flex-start;
            }
        }
    }
}
@media (min-width: 0) and (max-width: 767px){
    .viibes__home_slovakia_vs_belarus{
        padding: 11rem 0;
        &_inner{
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            &_left, &_right{
                width: 100%;
                gap: 2rem;
                padding-right: 0;
                div{
                    gap: 1rem;
                }
            }
        }
    }
}
